<template>
    <div class="pictureList_text">
      {{textStr}}
    </div>
</template>

<script>
export default {
  name: 'pictureList_text',
  props: {
    textStr: {
      type: String,
      default: '提示文字',
    },
  },
  created() {
  },

};
</script>

<style scoped lang="less">
  .pictureList_text{
    line-height: 0;
    position: relative;
    top: -10px;
    color: #606266;
  }

</style>
