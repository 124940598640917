import BasePage from '../../../../../components/table_page';

export default {
  name: 'detail',
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {};
  },
  methods: {
    afterGetList() {
      this.exportParams = this.paramsProps;
      return true;
    },
  },
  created() {
    this.getConfigList('mdm_related_contracts_detail');
  },
};
