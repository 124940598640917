<script>
import TablePage from '../../../../../components/table_page';
import Modal from '../../../../../components/modal';
// import request from '../../../../../utils/request';
import Form from '../form';
import RelatedContractsForm from '../components/related_contracts.vue';
import productLog from '../product_log';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    RelatedContractsForm,
    productLog,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmProductController/pageList',
      params: {
        // code: '',
        productLevelCode: '',
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      // this.params.code = val;
      this.params.productLevelCode = val;
      this.getList();
    },
  },
  created() {
    this.getConfigList('products_management_list');
  },
  methods: {
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
        this.resetTree();
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      console.log(val);
      if (!this.beforeModalClick({ val, row })) return;
      this.formName = 'Form';
      this.formConfig = { };
      this.modalConfig.title = '新增';
      // console.log(row);
      if (row.isShelf === 'Y' && val.code !== 'view' && val.code !== 'related_contracts' && val.code !== 'product_log') {
        this.$alert('只能编辑“下架”状态的商品数据');
      } else if (val.code === 'edit' && row.isShelf === 'N') {
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '编辑';
        this.openFull();
      }
      if (val.code === 'add') {
        this.formConfig = {
          type: 'add',
        };
        this.openFull();
      }
      if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          code: 'view',
          buttonCode: 'view',
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      } else if (val.code === 'related_contracts') {
        this.formName = 'RelatedContractsForm';
        this.modalConfig = {
          title: '相关合同',
          width: '80%',
        };

        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
          productCode: row.productCode,
        };

        this.openDrawer();
      } else if (val.code === 'product_log') {
        this.formName = 'productLog';
        this.modalConfig.title = '上下架日志';
        this.propsObjInData.row = row;
        this.openFull();
      }
    },
  },
};
</script>
