<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import ProductList from '../components/product_list.vue';
import pictureListText from '../components/pictureList_text/pictureList_text.vue';

formCreate.component('ProductList', ProductList);
formCreate.component('pictureListText', pictureListText);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      formFunctionCode: 'mdm_products_management_form',
      row: null,
      materialList: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  methods: {
    // 设置规则
    setRule(v) {
      console.log(v);
      const item = v;
      if (item.field === 'endDate') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            relation: {
              beginField: 'beginDate',
              endField: 'endDate',
              gtNow: true,
            },
          },
        });
      }
      if (item.field === 'beginDate') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            relation: {
              beginField: 'beginDate',
              endField: 'endDate',
              gtNow: true,
            },
          },
        });
      }

      if (item.field === 'materialName') { // 选择产品
        item.props = {
          ...item.props,
          params: {
            functionCode: 'product_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: item.title,
        };
      }

      if (item.field === 'productType') {
        Object.assign(item, {
          on: {
            change(val) {
              let state = true;
              if (val === 'standard') {
                state = true;
              } else {
                state = false;
              }

              that.updateRules({
                spec: {
                  props: {
                    disabled: state,
                  },
                },
                saleUnit: {
                  props: {
                    disabled: state,
                  },
                },
                baseUnit: {
                  props: {
                    disabled: state,
                  },
                },
                materialList: {
                  props: {
                    productType: val,
                    disableds: !(val === 'range'),
                  },
                },
              });

              that.setValue({
                productLevelCode: null,
                spec: '',
                materialList: [],
              });
              if (that.getFieldValue('saleUnit')) {
                that.setValue({
                  saleUnit: val[0].saleUnit,
                });
              }
              if (that.getFieldValue('baseUnit')) {
                that.setValue({
                  baseUnit: val[0].baseUnit,
                });
              }
            },
          },
        });
      } else if (item.field === 'productLevelCode') {
        Object.assign(item, {
          restful: '/mdm/mdmProductLevelController/productLevelSelectList',
          optionsKey: { label: 'productLevelName', value: 'productLevelCode' },
          props: {
            ...this.props,
            clearable: true,
            filterable: true,
            remote: true,
            remoteParams: 'productLevelName',
          },
        });
      } else if (item.field === 'videoList') {
        Object.assign(item, {
          props: {
            ...item.props,
            accept: 'video/*',
            fileMsg: '请上传视频文件',
          },
        });
      } else if (item.field === 'pictureList') {
        Object.assign(item, {
          props: {
            ...item.props,
            limitSize: 'mdm_product_pic_size',
            limitAccept: 'mdm_product_pic_type',
          },
        });
      } else if (item.field === 'pictureListText') {
        Object.assign(item, {
          props: {
            textStr: '请上传图片文件',
          },
        });
      }
      return item;
    },

    // 完成渲染
    formComplete() {
      const { type, id } = this.formConfig;
      const materialName = this.getRule('materialName');
      materialName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            materialName: e[0].materialName,
            materialCode: e[0].materialCode,
            ext4: e[0].ext4, // 产品类型
            materialLevel: e[0].materialName,
            productLevelCode: e[0].productLevelCode,
            productLevelName: e[0].productLevelName,
            ext3: e[0].ext3, // 包装属性
            ext2: e[0].ext2, // 香型
            materialType: e[0].materialType, // ERP基本分类
            ext5: e[0].ext5,
            spec: e[0].specification,
            productType: e[0].materialType, // 商品类型
            productName: e[0].materialName,
            productCode: e[0].materialCode,
          });
          this.materialList = e;
        } else {
          this.setValue({
            materialName: null,
            materialCode: null,
            ext4: null,
            materialLevel: null,
            productLevelCode: null,
            productLevelName: null,
            ext3: null,
            ext2: null,
            materialType: null,
            ext5: null,
            spec: null,
            productType: null,
            productName: null,
            productCode: null,
          });
          this.materialList = [];
        }
      };

      if (type === 'edit' || type === 'view') {
        request.get('/mdm/mdmProductController/detail', { id }).then((res2) => {
          if (res2.success) {
            const formDatum = res2.result;
            const list = res2.result.pictureList;
            if (list && list.lenght > 0) {
              list.map((v) => {
                Object.assign(v, {
                  url: v.urlAddress,
                });

                return v;
              });

              this.updateRules({
                pictureList: {
                  props: {
                    fileList: list,
                  },
                },
              });
            }

            formDatum.pictureList = formDatum.pictureList
              ? formDatum.pictureList.map((v) => v.urlAddress)
              : [];
            formDatum.introductionVo = formDatum.introductionVo
              ? formDatum.introductionVo.introductionText
              : '';
            this.row = formDatum;
            if (formDatum.productType === 'standard') {
              that.updateRules({
                spec: {
                  props: {
                    disabled: true,
                  },
                },
                saleUnit: {
                  props: {
                    disabled: true,
                  },
                },
                baseUnit: {
                  props: {
                    disabled: true,
                  },
                },
                materialList: {
                  props: {
                    productType: 'standard',
                    disableds: true,
                  },
                },
              });
            }
            if (formDatum.productType === 'range') {
              that.updateRules({
                materialList: {
                  props: {
                    productType: 'range',
                    disableds: false,
                  },
                },
              });
            }
            if (!formDatum.videoList) {
              formDatum.videoList = [];
            } else {
              formDatum.videoList = formDatum.videoList.map((v) => {
                const item = v;
                item.url = item.urlAddress;
                return item;
              });
            }
            this.materialList = res2.result.materialList || [];
            this.setValue({
              ...formDatum,
              materialName: res2.result.productName,
              materialCode: res2.result.productCode,
              materialType: res2.result.productType,
              specification: res2.result.spec,
            });
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData();
      formData.materialList = this.materialList;
      if (formData) {
        let params = {};
        let fileList = formData.pictureList;
        if (fileList && fileList.length > 0) {
          fileList = fileList.map((v) => ({
            urlAddress: v,
          }));
        }
        let { videoList } = formData;
        if (videoList && videoList.length > 0) {
          videoList = videoList.map((v) => {
            const item = v;
            item.urlAddress = item.url;
            return item;
          });
        }

        formData.pictureList = fileList || [];
        formData.videoList = videoList || [];

        formData.introductionVo = {
          introductionText: formData.introductionVo,
        };
        if (formData.productType === 'gift_package' || formData.productType === 'standard') {
          const materialListEmpty = formData.materialList.some((item) => (item.count === '' || item.count === null || item.count === 0 || item.count === '0'));

          if (materialListEmpty) {
            this.$message.warning('请填写物料数量后提交');
            return false;
          }
        }
        let url = '/mdm/mdmProductController/save';
        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmProductController/update';
          params = Object.assign(this.row, formData);
        } else {
          params = formData;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      }
    },
  },
  mounted() {},
  created() {
    // this.createRule();
  },
};
</script>
